import netherDungeons from "../assets/netherdungeons.jpg"
import nd1 from "../assets/portfolio_imgs/nd1.jpg"
import nd2 from "../assets/portfolio_imgs/nd2.jpg"
import nd3 from "../assets/portfolio_imgs/nd3.jpg"
import nd4 from "../assets/portfolio_imgs/nd4.jpg"
import nd5 from "../assets/portfolio_imgs/nd5.jpg"

import awitaDuo from "../assets/awitaduo_front.jpg"
import aw1 from "../assets/portfolio_imgs/aw1.jpg"
import aw2 from "../assets/portfolio_imgs/aw2.jpg"
import aw3 from "../assets/portfolio_imgs/aw3.jpg"
import aw4 from "../assets/portfolio_imgs/aw4.jpg"

import turboAmigo from "../assets/turboamigo_front.jpg"
import ta1 from "../assets/portfolio_imgs/ta1.jpg"
import ta2 from "../assets/portfolio_imgs/ta2.jpg"
import ta3 from "../assets/portfolio_imgs/ta3.jpg"
import ta4 from "../assets/portfolio_imgs/ta4.jpg"
import ta5 from "../assets/portfolio_imgs/ta5.jpg"

import bottleCracks from "../assets/bottlecracks_front.jpg"
import bc1 from "../assets/portfolio_imgs/bc1.jpg"
import bc2 from "../assets/portfolio_imgs/bc2.jpg"
import bc3 from "../assets/portfolio_imgs/bc3.jpg"

import dungeontime from "../assets/dungeontime_front.jpg"
import dt1 from "../assets/portfolio_imgs/dt1.jpg"
import dt2 from "../assets/portfolio_imgs/dt2.jpg"
import dt3 from "../assets/portfolio_imgs/dt3.jpg"
import dt4 from "../assets/portfolio_imgs/dt4.jpg"

import wijiWaji from "../assets/wijiwaji_front.jpg"
import ww1 from "../assets/portfolio_imgs/ww1.jpg"
import ww2 from "../assets/portfolio_imgs/ww2.jpg"
import ww3 from "../assets/portfolio_imgs/ww3.jpg"
import ww4 from "../assets/portfolio_imgs/ww4.jpg"
import ww5 from "../assets/portfolio_imgs/ww5.jpg"

import bugsNBog from "../assets/bugsnbog_front.jpg"
import bnb1 from "../assets/portfolio_imgs/bnb1.jpg"
import bnb2 from "../assets/portfolio_imgs/bnb2.jpg"
import bnb3 from "../assets/portfolio_imgs/bnb3.jpg"
import bnb4 from "../assets/portfolio_imgs/bnb4.jpg"

import labotrain from "../assets/labotrain_front.jpg"
import lt1 from "../assets/portfolio_imgs/lt1.jpg"
import lt2 from "../assets/portfolio_imgs/lt2.jpg"
import lt3 from "../assets/portfolio_imgs/lt3.jpg"



export const portfolioData = [
    {
        id:"nether-dungeons",
        title: "Nether Dungeons",
        description: "Action-packed dungeon crawler. Battle through procedurally generated dungeons, face relentless enemies, and defeat epic bosses.",
        imgDir: netherDungeons,
        youtubeID: "drajw6QvoGA",
        prevImgs: [
            nd1,
            nd2,
            nd3,
            nd4,
            nd5
        ],
        factsData:{
            "Project Type": "Work project",
            "Software Used": "Unity, Git",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "Nether Dungeons is an action-packed dungeon crawler. Battle through procedurally generated dungeons, face relentless enemies, and defeat epic bosses. Customize your hero, wield diverse weapons, and survive intense challenges as you descend into ever-changing, dangerous depths.\n\nI contributed to the development of this game for approximately one year at Arakuma Studio as a programmer. I worked closely with a team of talented programmers and artists. Additionally, we collaborated with Farfadox, a YouTuber with over 3 million subscribers, to help bring his vision for the game to life.",
        pages:{
            "STEAM PAGE": "https://store.steampowered.com/app/3096270/NETHER_DUNGEONS/",
            "GOOGLE PLAY": "https://play.google.com/store/apps/details?id=com.ArakumaStudio.NetherDungeons",
            "APP STORE": "https://apps.apple.com/us/app/nether-dungeons/id6737908225"
        }
    },
    {
        id:"awita-duo-elemental",
        title: "Awita: Duo Elemental",
        description: "With the company of Awita's unique characters, explore fascinating biomes and solve clever puzzles.",
        imgDir: awitaDuo,
        youtubeID: "4xXoXnvBODw",
        prevImgs: [
            aw1,
            aw2,
            aw3,
            aw4
        ],
        factsData:{
            "Project Type": "Work project",
            "Software Used": "Unity, Git",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "Awita: Elemental Duo is a platformer puzzle game where the player must switch between two characters to solve each level using their unique abilities\n\nThis game was developed in collaboration with other talented programmers and artists at Arakuma Studio.",
        pages:{
            "GOOGLE PLAY": "https://play.google.com/store/apps/details?id=com.ArakumaStudio.AwitaGame",
            "APP STORE": "https://apps.apple.com/pe/app/awita-duo-elemental/id6478712744"
        }
    },
    {
        id:"turbo-amigo",
        title: "Turbo Amigo",
        description: "Take care of Turbo, your virtual pet, and embark on a journey full of fun and games! Feed, clean, and keep Turbo healthy.",
        imgDir: turboAmigo,
        youtubeID: "uNlyHZeauDw",
        prevImgs: [
            ta1,
            ta2,
            ta3,
            ta4,
            ta5
        ],
        factsData:{
            "Project Type": "Work project",
            "Software Used": "Unity, Git",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "Turbo Amigo is a virtual pet game where you must take care of Turbo, your new friend. Feed, clean, and keep Turbo healthy to unlock new games and activities.\n\nThis game was developed in collaboration with other talented programmers and artists at Arakuma Studio. We also worked with xTurbo, a YouTuber with over 6 million subscribers, to create the game he envisioned.",
        pages:{
            "GOOGLE PLAY": "https://play.google.com/store/apps/details?id=com.ArakumaStudio.TurboAmigo",
            "APP STORE": "https://apps.apple.com/pe/app/turbo-amigo/id6474603038"
        }
    },
    {
        id:"bottle-cracks",
        title: "Bottle Cracks!",
        description: "Cooperative multiplayer game, playable locally and online. Choose just one key and jump to the end without your bottle cracking!",
        imgDir: bottleCracks,
        youtubeID: "gAvhJufnFG0",
        prevImgs: [
            bc1,
            bc2,
            bc3
        ],
        factsData:{
            "Project Type": "Personal",
            "Software Used": "Unity, Photon Pun",
            "Languages Used": "C#",      
            "Primary Role(s)": "Solo Developer"
        },
        fullDescription: "Bottle Cracks! is my second online multiplayer game, designed for up to 16 players. The game's concept revolves around guiding a bottle to the end of each level using only a single key to make it jump. However, it's important to be careful with the applied force, as the bottle can crack if too much force is used.\n\nThe idea for this game came to me when I challenged myself to create a game for a sleepover event taking place in just two weeks. The challenge was that we had six people but only one keyboard. Consequently, I had to come up with a game that allowed each character to control a bottle using only one key.\n\nTo develop Bottle Cracks!, I drew inspiration from various games such as Getting Over It with Bennett Foddy, Golfin Over It, Golf With Friends, and Jump King. Initially, the game could only be played locally, but after a few weeks, I managed to implement online gameplay. I continue to update the game periodically, and it has received great acclaim from players.\n\nMy future plans for Bottle Cracks! involve enhancing and completely redesigning the game with the intention of releasing it on the Steam platform. I am excited about the possibilities this represents and look forward to offering an even more captivating experience for players.",
        pages:{
            "ITCH.IO PAGE": "https://frowsyfrog.itch.io/bottle-cracks"
        }
    },
    {
        id:"here-comes-the-wiji-waji",
        title: "Here Comes The Wiji Waji",
        description: "Online multiplayer game. Several creatures went to a Playground, and one of them is the Wiji Waji, who is blood thirsty.",
        imgDir: wijiWaji,
        youtubeID: "1QO-nBrzoM8",
        prevImgs: [
            ww1,
            ww2,
            ww3,
            ww4,
            ww5
        ],
        factsData:{
            "Project Type": "Personal",
            "Software Used": "Unity, Photon Pun",
            "Languages Used": "C#",      
            "Primary Role(s)": "Solo Developer"
        },
        fullDescription: "Here Comes The Wiji Waji was my first online multiplayer game project. The premise of the game is that several creatures gather in a Playground, and among them is the Wiji Waji, whose objective is to kill the other players. Participants must collect artifacts to unlock a gun, and giving them the opportunity to defeat the Wiji Waji.\n\nI developed this game with the purpose of learning to program an online multiplayer game and also to have something to play with my friends. I focused on programming, sound design, and character modeling, while the 3D models of the environment were obtained through free assets.\n\nWhen creating this game, I was inspired by three titles: 'Escape the Beast', a Minecraft minigame, and 'Murder Mystery 2', from Roblox.   ",
        pages:{
            "ITCH.IO PAGE": "https://frowsyfrog.itch.io/here-comes-the-wiji-waji"
        }
    },
    {
        id:"bugs-n-bog",
        title: "Bugs 'n Bog",
        description: "Game made for a 3-day game jam that I did as part of a team. Play as a frog waiter, serve your best and maintain a 5-star reputation.",
        imgDir: bugsNBog,
        youtubeID: "VRU_z8a-gtY",
        prevImgs: [
            bnb1,
            bnb2,
            bnb3,
            bnb4
        ],
        factsData:{
            "Project Type": "GameJam / group project",
            "Software Used": "Unity",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "Bugs 'n Bog was a game I developed in just 72 hours, assuming the role of a programmer within a team. The game revolves around being a frog waiter responsible for serving all the customers to maintain the restaurant's 5-star reputation.\n\nParticipating in this project was an incredibly enriching experience. I really enjoyed the process of getting to know our team better by participating in various icebreaker games. Subsequently, we brainstormed and organized effectively, resulting in a very enjoyable and successful development experience.",
        pages:{
            "ITCH.IO PAGE": "https://triplecatgames.itch.io/bugs-n-bog"
        }
    },
    {
        id:"dungeon-time",
        title: "The Dungeon of Time",
        description: "Game made for a 2-day game jam that I did as part of a team. Go through the 3 rooms of the dungeon without getting caught!",
        imgDir: dungeontime,
        youtubeID: "QfoOCvpLdrQ",
        prevImgs: [
            dt1,
            dt2,
            dt3,
            dt4
        ],
        factsData:{
            "Project Type": "GameJam / group project",
            "Software Used": "Unity",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "Delve into The Dungeon of Time, a mesmerizing PSX-inspired roguelike game crafted by a team of six for the UPC 2023 game jam. Play as a time-manipulating magician and navigate three rooms guarded by creatures. Your task? Race against time to conquer each room as quickly as possible. Can you master temporal magic and emerge victorious in this challenging adventure?",
        pages:{
            "ITCH.IO PAGE": "https://salador.itch.io/the-dungeon-of-time"
        }
    },
    {
        id:"labotrain",
        title: "LaboTrain",
        description: "University project using Artificial Intelligence techniques. Compete in a race against an AI opponent in 5 procedurally generated mazes.",
        imgDir: labotrain,
        youtubeID: "Pitb6qiQ5rA",
        prevImgs:[
            lt1,
            lt2,
            lt3
        ],
        factsData:{
            "Project Type": "University project",
            "Software Used": "Unity",
            "Languages Used": "C#",      
            "Primary Role(s)": "Programmer"
        },
        fullDescription: "LaboTrain is a university project developed in just one day. It challenges you to race against an artificial intelligence through 5 procedurally generated mazes. Move your character and reach the treasure chest. If the AI manages to exit the maze before you do, you will lose.\n\nThis project was created to apply knowledge of artificial intelligence, both for maze generation and enemy pathfinding within the game. The project is completely open-source, allowing anyone to edit the game to their liking and explore its inner workings.",
        pages:{
            "GITHUB PAGE": "https://github.com/FrowsyFrog/LaboTrain"
        }
    }
]